import React from 'react';
import MediumCard from '../../components/MediumCard';
import chalkboardSvg from '../../../assets/svg/card/chalkboard-user-solid.svg';
import StatsContainer from '../../components/StatsContainer';
import globeSvg from '../../../assets/svg/card/globe-solid.svg';
import expertSvg from '../../../assets/svg/card/user-tie-solid.svg';
import organizationSvg from '../../../assets/svg/card/earth-europe-solid.svg';
import buildingSvg from '../../../assets/svg/card/building-solid.svg';
import peopleSvg from '../../../assets/svg/card/people-group-solid.svg';
import lvvk from '../../../assets/images/partners-sponsors-organizations/lvvk.jpg';
import wmf from '../../../assets/images/partners-sponsors-organizations/wmf.png';
import erasmus from '../../../assets/images/partners-sponsors-organizations/Erasmus+_with_baseline-pos-ALL_lang_LT.png';
import lvra from '../../../assets/images/partners-sponsors-organizations/lvra.PNG';
import lipria from '../../../assets/images/partners-sponsors-organizations/LIPRIA.png';
import aeht from '../../../assets/images/partners-sponsors-organizations/aeht.png';
import pd from '../../../assets/images/partners-sponsors-organizations/patyciu-dezute-logo.webp';
import esfivp from '../../../assets/images/partners-sponsors-organizations/ESFIVP-I-3.jpg';

export default function BenefitsCards() {
	return (
		<section className="py-16 flex flex-col items-center px-32 max-2xl:px-16 max-md:px-8">
			<h2 className="text-center ">Kodėl VESK?</h2>
			<div className="h-2 w-32 bg-yellow rounded-full mb-8 mt-4"></div>
			<div className="grid grid-cols-3 w-full gap-8 max-2xl:grid-cols-2 max-lg:grid-cols-1 mb-8">
				<MediumCard
					img={chalkboardSvg}
					alt={'Chalkboard-User Icon'}
					title={'Praktinis mokymas'}
					paragraph={'Modernios praktinio mokymo bazės'}
				/>
				<MediumCard
					img={globeSvg}
					alt={'Globe Icon'}
					title={'Praktika užsienyje'}
					paragraph={'Galimybė praktiką atlikti net 20 šalių'}
				/>
				<MediumCard
					img={expertSvg}
					alt={'User-Tie Icon'}
					title={'Ekspertų komanda'}
					paragraph={'Profesionali mokytojų komanda, kviestiniai lektoriai'}
				/>
				<MediumCard
					img={organizationSvg}
					alt={'Earth-Europe Icon'}
					title={'Organizacijos'}
					paragraph={'Narystė tarptautinėse ir nacionalinėse organizacijose'}
				/>
				<MediumCard
					img={peopleSvg}
					alt={'People-Group Icon'}
					title={'Konkursai'}
					paragraph={'Profesinio meistriškumo konkursai'}
				/>
				<MediumCard
					img={buildingSvg}
					alt={'Building Icon'}
					title={'Apgyvendinimas'}
					paragraph={'Bendrabutis miesto centre'}
				/>
			</div>
			<div className="grid grid-cols-4 w-full gap-8 max-2xl:grid-cols-2 max-lg:grid-cols-1 mb-8">
				<StatsContainer
					title={'1300+'}
					text={'Svečių sektoriniuose centruose'}
				/>
				<StatsContainer title={'25%'} text={'Mokosi pameistrystės forma'} />
				<StatsContainer title={'25'} text={'Meistriškumo konkursai'} />
				<StatsContainer title={'93%'} text={'Patenkintų studentų'} />
			</div>
			<div className="flex flex-col w-full gap-16">
				<span className="grid grid-cols-6 gap-8  max-lg:grid-cols-2">
					<span className="flex justify-center items-center">
						<img
							className="w-24 h-24 max-md:w-16 max-md:h-16"
							src={lvvk}
							alt=""
						/>
					</span>
					<span className="flex justify-center items-center">
						<img
							className="w-24 h-24 max-md:w-16 max-md:h-16"
							src={wmf}
							alt=""
						/>
					</span>
					<span className="flex justify-center items-center">
						<img
							className="w-24 h-24  max-md:w-16 max-md:h-16"
							src={lvra}
							alt=""
						/>
					</span>
					<span className="flex justify-center items-center">
						<img
							className="w-24 h-24  max-md:w-16 max-md:h-16"
							src={aeht}
							alt=""
						/>
					</span>
					<span className="flex justify-center items-center">
						<img
							className="w-24 h-24 max-md:w-16 max-md:h-16"
							src={esfivp}
							alt=""
						/>
					</span>
					<span className="flex justify-center items-center">
						<img
							className="w-24 h-24 max-md:w-16 max-md:h-16"
							src={pd}
							alt=""
						/>
					</span>
				</span>
				<span className="flex w-full justify-center flex-wrap gap-8">
					<img
						className="w-96 h-24 max-lg:w-72 max-lg:h-16 max-md:w-64 max-md:h-12"
						src={lipria}
						alt=""
					/>
					<img
						className="w-96 h-24 max-lg:w-72 max-lg:h-16 max-md:w-64 max-md:h-12"
						src={erasmus}
						alt=""
					/>
				</span>
			</div>
		</section>
	);
}
