import React from 'react';

export default function StatsContainer({ title, text }) {
	return (
		<div className="flex flex-col items-center gap-2">
			<h1 className="text-green ">{title}</h1>
			<p className="text-xl">{text}</p>
		</div>
	);
}
