import React, { useEffect, useRef, useState } from 'react';
import ProfessionCard from '../core/components/professions/ProfessionCard';
import filter from '../assets/svg/filter/filter-solid.svg';
import chevron from '../assets/svg/nav/chevron-down-solid.svg';
import chalk from '../assets/svg/card/chalkboard-user-solid.svg';
import house from '../assets/svg/card/building-solid.svg';
export default function ProfessionsPage({ professions }) {
	const [isFilterClicked, setIsFilterClicked] = useState(false);
	const filterRef = useRef(null);

	const [isTypeClicked, setIsTypeClicked] = useState(false);
	const [selectedCategories, setSelectedCategories] = useState([]);

	const [isCityClicked, setIsCityClicked] = useState(false);
	const [selectedCities, setSelectedCities] = useState([]);

	const toggleCategory = (category) => {
		setSelectedCategories((prevCategories) =>
			prevCategories.includes(category)
				? prevCategories.filter((cat) => cat !== category)
				: [...prevCategories, category]
		);
	};

	const toggleCity = (city) => {
		setSelectedCities((prevCities) =>
			prevCities.includes(city)
				? prevCities.filter((cit) => cit !== city)
				: [...prevCities, city]
		);
	};

	const uniqueCategories = Array.from(
		new Set(professions.map((item) => item.fields.category))
	);
	const uniqueCities = Array.from(
		new Set(professions.map((item) => item.fields.location))
	);

	const filteredProfessions = professions.filter((profession) => {
		const isCategoryMatch =
			selectedCategories.length === 0 ||
			selectedCategories.includes(profession.fields.category);
		const isCityMatch =
			selectedCities.length === 0 ||
			selectedCities.includes(profession.fields.location);
		return isCategoryMatch && isCityMatch;
	});

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (filterRef.current && !filterRef.current.contains(event.target)) {
				setIsFilterClicked(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<main className="pb-32 pt-40 flex flex-col gap-8  bg-neutral-50 px-32 max-2xl:px-16 max-sm:px-8">
			<h1 className="max-xl:self-center">Profesijos</h1>
			<div className="flex max-xl:self-center">
				<ul
					className=" relative select-none flex gap-8 max-sm:gap-4 flex-wrap"
					ref={filterRef}
				>
					<div
						className={`flex items-center gap-2 cursor-pointer px-4 py-1 rounded-lg ${
							isFilterClicked ? 'bg-neutral-200' : ''
						}`}
						onClick={() => setIsFilterClicked(!isFilterClicked)}
					>
						<img src={filter} alt="Filter" className="w-4" />
						<h3>Filtras</h3>
					</div>
					<div className="flex items-center gap-2 rounded-xl py-1 px-4 bg-white shadow-md">
						<h3 className="bg-gradient-to-tr from-green via-cyan to-yellow h-6 w-6 text-sm shadow-md flex items-center justify-center text-white rounded-full">
							{filteredProfessions.length}
						</h3>
						<h3>
							{filteredProfessions.length === 0
								? 'Profesijų'
								: filteredProfessions.length === 1
								? 'Profesija'
								: filteredProfessions.length > 9
								? 'Profesijų'
								: 'Profesijos'}
						</h3>
					</div>

					<div
						className={`absolute flex flex-col  top-16 bg-white p-4 rounded-lg border border-neutral-200 shadow-md ${
							isFilterClicked ? '' : 'hidden'
						}`}
					>
						<div className="flex gap-4 w-max max-xl:flex-col">
							<div className="flex flex-col cursor-pointer ">
								<div
									className="border border-neutral-200 w-full px-4 py-2 gap-2 rounded-md flex items-center"
									onClick={() => setIsTypeClicked(!isTypeClicked)}
								>
									<img
										src={chalk}
										alt="Chalkboard"
										className="mr-2 max-[355px]:hidden"
									/>
									<h3 className="mr-4">Mokymo tipas</h3>
									<div className="ml-auto flex gap-2">
										<div
											className={`bg-cyan flex items-center justify-center rounded-full h-6 w-6 ml-auto text-center text-sm leading-none  
										text-white ${selectedCategories.length === 0 ? 'hidden' : ''}`}
										>
											{selectedCategories.length}
										</div>
										<img
											src={chevron}
											alt="Chevron"
											className={`duration-150 ${
												isTypeClicked ? 'rotate-180' : ''
											}`}
										/>
									</div>
								</div>
								<div
									className={`${
										isTypeClicked ? '' : 'hidden'
									} border border-neutral-200 rounded-md mt-4 p-4 flex gap-4 flex-col`}
								>
									{uniqueCategories.map((category, index) => (
										<div
											key={index}
											className="flex items-center cursor-pointer"
											onClick={() => toggleCategory(category)}
										>
											<li className="py-1 px-2">{category}</li>
											{selectedCategories.includes(category) && (
												<div className="w-2 h-2 bg-cyan rounded-full ml-auto"></div>
											)}
										</div>
									))}
								</div>
							</div>
							<div className="flex flex-col cursor-pointer">
								<div
									className="border border-neutral-200 w-full px-4 py-2 gap-2 rounded-md flex items-center text-sm leading-none"
									onClick={() => setIsCityClicked(!isCityClicked)}
								>
									<img
										src={house}
										alt="Building"
										className="mr-2 max-[355px]:hidden"
									/>
									<h3 className="mr-4">Miestas</h3>
									<div className="flex gap-2 ml-auto">
										<div
											className={`bg-cyan flex items-center justify-center rounded-full h-6 w-6 text-center text-sm leading-none text-white  ${
												selectedCities.length === 0 ? 'hidden' : ''
											}`}
										>
											{selectedCities.length}
										</div>
										<img
											src={chevron}
											alt="Chevron"
											className={`duration-150 ${
												isCityClicked ? 'rotate-180' : ''
											}`}
										/>
									</div>
								</div>
								<div
									className={`${
										isCityClicked ? '' : 'hidden'
									} border border-neutral-200 rounded-md mt-4 p-4 flex gap-4 flex-col`}
								>
									{uniqueCities.map((city, index) => (
										<div
											key={index}
											className="flex items-center cursor-pointer"
											onClick={() => toggleCity(city)}
										>
											<li className="py-1 px-2">{city}</li>
											{selectedCities.includes(city) && (
												<div className="w-2 h-2 bg-cyan rounded-full ml-auto"></div>
											)}
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="flex pt-4">
							<button
								className="ml-auto"
								onClick={() => {
									setSelectedCategories([]);
									setSelectedCities([]);
									setIsFilterClicked(!isFilterClicked);
								}}
							>
								Išvalyti filtrą
							</button>
						</div>
					</div>
				</ul>
			</div>

			<div className="flex gap-16 flex-wrap max-xl:justify-center">
				{filteredProfessions.map((profession, index) => (
					<ProfessionCard
						key={index}
						title={profession.fields.title}
						img={profession.fields.image.fields.file.url}
						category={profession.fields.category}
						duration={profession.fields.duration}
						location={profession.fields.location}
					/>
				))}
			</div>
		</main>
	);
}
