import React from 'react';
import ApplicantCard from '../../components/applicants/ApplicantCard';
export default function ApplicantFeature({
	title,
	apply,
	info,
	docs,
	buttons,
	date,
}) {
	return (
		<section className="w-full px-96 max-xl:px-16 max-xl:ml-16 max-sm:ml-0 max-sm:px-8 pr-64 flex flex-col gap-4 pt-40 mb-32">
			<h1 className="mb-16">{title}</h1>

			<div className="flex flex-col gap-8 ">
				<ApplicantCard
					richTxt={apply}
					heading={'Kaip pateikti prašymą?'}
					buttons={buttons}
					date={date}
				/>
				<ApplicantCard richTxt={info} heading={'Svarbi informacija'} />
				<ApplicantCard richTxt={docs} heading={'Reikalingi dokumentai'} />
			</div>
		</section>
	);
}
