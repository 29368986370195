import React, { useState, useEffect } from 'react';
import ContactElement from '../../components/contacts/ContactElement';
import emailSvg from '../../../assets/svg/contacts/envelope-solid.svg';
import phoneSvg from '../../../assets/svg/contacts/phone-solid.svg';
import mapSvg from '../../../assets/svg/contacts/map-location-dot-solid.svg';
import infoSvg from '../../../assets/svg/contacts/details-svgrepo-com.svg';
import EmployeeCard from '../../components/contacts/EmployeeCard';
import school from '../../../assets/svg/card/school-solid.svg';
import Filter from '../../components/Filter';

export default function ContactsMap({ employees, departments }) {
	const [isRender, setRender] = useState('');
	const sortedDepartments = departments.sort(
		(a, b) => a.fields.id - b.fields.id
	);
	useEffect(() => {
		if (sortedDepartments.length > 0) {
			setRender(sortedDepartments[0].fields.name);
		}
	}, [sortedDepartments]);

	const handleRenderChange = (newRenderState) => {
		setRender(newRenderState);
	};

	const selectedDepartment = sortedDepartments.find(
		(sortedDepartment) =>
			sortedDepartment.fields.name.toLowerCase() === isRender.toLowerCase()
	);
	if (!selectedDepartment) {
		return null;
	}

	const sortedEmployees = employees.sort(
		(a, b) => a.fields.order - b.fields.order
	);
	return (
		<section className="pt-40 pb-32 flex flex-col gap-8 px-40 max-2xl:px-16 max-md:px-8  bg-neutral-50 max-xl:items-center">
			<h1 className="">Pagrindiniai Kontaktai</h1>

			<Filter
				options={departments.map((department) => department.fields.name)}
				svg={school}
				alt={'Department'}
				onRenderChange={handleRenderChange}
			/>

			<div className="flex flex-row max-xl:flex-col gap-8 bg-white p-8 rounded-xl shadow-md">
				<div className="flex flex-row flex-wrap gap-8 w-1/2 max-xl:w-full max-xl:order-2">
					<ContactElement
						img={emailSvg}
						alt={'Email Icon'}
						smallText={'El. Paštas'}
						bigText={selectedDepartment.fields.email}
					/>
					<ContactElement
						img={mapSvg}
						alt={'Map Icon'}
						smallText={'Adresas'}
						bigText={selectedDepartment.fields.adress}
					/>
					<ContactElement
						img={phoneSvg}
						alt={'Phone Icon'}
						smallText={'Telefono nr.'}
						bigText={selectedDepartment.fields.phone}
					/>
					<div className="flex flex-row items-center gap-8">
						<img src={infoSvg} alt="Info Icon" className="w-10 h-10" />
						<div>
							<p className="text-gray-500">Rekvizitai</p>
							<h3>Įmonės kodas: 305239644</h3>
							<h3>PVM kodas: LT100012604312</h3>
						</div>
					</div>
				</div>

				<div className="w-1/2 h-[512px] max-xl:w-full">
					<iframe
						title="Google Maps"
						className="w-full h-full border-4 border-cyan bg-white rounded-lg shadow-md"
						src={selectedDepartment.fields.mapUrl}
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
				</div>
			</div>
			{isRender.toLocaleLowerCase() ===
				sortedDepartments[0].fields.name.toLocaleLowerCase() && (
				<>
					<h1 className="text-cyan mt-16">Kiti kontaktai</h1>
					<div className="flex flex-wrap gap-16 justify-between max-xl:justify-center">
						{sortedEmployees.map((employee, index) => (
							<EmployeeCard
								key={index}
								photo={employee.fields.photo.fields.file.url}
								name={employee.fields.name}
								type={employee.fields.position}
								email={employee.fields.email}
								phone={employee.fields.phone}
							/>
						))}
					</div>
				</>
			)}
		</section>
	);
}
