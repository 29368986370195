import React, { useState, useEffect } from 'react';
import Filter from '../../components/Filter';
import calendar from '../../../assets/svg/filter/calendar-days-solid.svg';
import ProjectCard from '../../components/projects/ProjectCard';

export default function ProjectFeature({ type, title, projectData }) {
	const [projects, setProjects] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [filterOptions, setFilterOptions] = useState([]);

	useEffect(() => {
		const projectTypes = projectData.filter(
			(project) => project.fields.type === type
		);
		setProjects(projectTypes);

		const newestStartDate = Math.max(
			...projectTypes.map((project) =>
				new Date(project.fields.startDate).getFullYear()
			)
		);

		const filteredProjects = projectTypes.filter((project) => {
			const startDate = new Date(project.fields.startDate);
			const endDate = new Date(project.fields.endDate);
			const startYear = startDate.getFullYear();
			const endYear = endDate.getFullYear();
			const targetYear = newestStartDate;

			return targetYear >= startYear && targetYear <= endYear;
		});
		setFiltered(filteredProjects);

		const startDates = projectTypes.map((project) => {
			const startDate = new Date(project.fields.startDate);
			return startDate.getFullYear().toString();
		});

		const uniqueSortedDates = Array.from(new Set(startDates)).sort(
			(a, b) => b - a
		);
		setFilterOptions(uniqueSortedDates);
	}, [projectData, type]);

	const handleFilterChange = (year) => {
		const filteredProjects = projects.filter((project) => {
			const startDate = new Date(project.fields.startDate);
			const endDate = new Date(project.fields.endDate);

			const startYear = startDate.getFullYear();
			const endYear = endDate.getFullYear();
			const targetYear = parseInt(year);

			return targetYear >= startYear && targetYear <= endYear;
		});

		setFiltered(filteredProjects);
	};

	return (
		<section className="w-full px-96 max-xl:px-32 max-sm:px-8 pr-64 flex flex-col gap-4 pt-40 mb-32 ">
			<h1 className="mb-8 max-sm:text-center">{title}</h1>

			{filterOptions && filterOptions.length > 0 && (
				<Filter
					options={filterOptions}
					onRenderChange={handleFilterChange}
					svg={calendar}
					alt={'Calendar'}
				/>
			)}

			<div className="mt-8 flex flex-col gap-8">
				{filtered.map((project, index) => (
					<ProjectCard
						key={index}
						images={project.fields.logo.map((logo) => logo.fields.file.url)}
						title={project.fields.title}
						number={project.fields.number}
						text={project.fields.shortDescription}
						type={project.fields.type}
					/>
				))}
			</div>
		</section>
	);
}
