import React from 'react';
import DocumentCard from '../core/components/DocumentCard';
import ReactMarkdown from 'react-markdown';

export default function DepartmentLP({ title, description, documents }) {
	return (
		<main className="pb-32 pt-40 flex flex-col gap-8 px-40 max-2xl:px-16 max-sm:px-8 bg-neutral-50">
			<h1>{title}</h1>
			<ReactMarkdown className="flex flex-col gap-4">
				{description}
			</ReactMarkdown>
			{documents && (
				<div>
					<DocumentCard title={'Dokumentai'} files={documents} />
				</div>
			)}
		</main>
	);
}
