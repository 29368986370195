import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SideNav from '../core/features/SideNav';
import ApplicantFeature from '../core/features/applicants/ApplicantFeature';

export default function Applicants({ state, data }) {
	const [isRender, setRender] = useState(state);
	const navigate = useNavigate();

	useEffect(() => {
		setRender(state);
	}, [state]);

	const handleRenderChange = (newRenderState) => {
		setRender(newRenderState);
	};
	const changeRouteFunction = (route) => {
		navigate(route);
	};

	const navigationItems = data
		.sort((a, b) => (a.fields.index || 0) - (b.fields.index || 0))
		.map((item) => ({
			key: item.fields.route,
			label: item.fields.title,
			route: `/stojantiesiems/${item.fields.route}`,
		}));

	function renderComponent() {
		const selectedAplicant = data.find(
			(item) => item.fields.route === isRender
		);

		if (!selectedAplicant) {
			return null;
		}

		return (
			<ApplicantFeature
				title={selectedAplicant.fields.title}
				apply={selectedAplicant.fields.howToApply}
				info={selectedAplicant.fields.importantInfo}
				docs={selectedAplicant.fields.documents}
				buttons={selectedAplicant.fields.buttons}
				date={selectedAplicant.fields.date}
			/>
		);
	}

	return (
		<main className="relative flex bg-neutral-50 min-h-screen overflow-hidden">
			<SideNav
				title={'Stojantiesiems'}
				onRenderChange={handleRenderChange}
				currentRenderState={isRender}
				navigationItems={navigationItems}
				changeRoute={changeRouteFunction}
			/>
			{renderComponent()}
		</main>
	);
}
