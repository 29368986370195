import React, { useEffect, useRef, useState } from 'react';
import chevron from '../../../assets/svg/nav/chevron-down-solid.svg';
import { Link } from 'react-router-dom';

export default function Nav({
	className,
	closeHamburger,
	applicantData,
	serviceData,
}) {
	const [dropdownStates, setDropdownStates] = useState({
		stojantiesiems: false,
		projektai: false,
		paslaugos: false,
	});

	const sortedApplicantData = applicantData.sort(
		(a, b) => a.fields.index - b.fields.index
	);
	const sortedServiceData = serviceData.sort((a, b) =>
		a.fields.title.localeCompare(b.fields.title)
	);

	const toggleDropdown = (dropdownName) => {
		setDropdownStates((prevStates) => {
			const newStates = { ...prevStates };
			Object.keys(newStates).forEach((key) => {
				newStates[key] = key === dropdownName ? !newStates[key] : false;
			});
			return newStates;
		});
	};
	const handleMouseEnter = (dropdownName) => {
		if (window.innerWidth > 1280) {
			toggleDropdown(dropdownName);
		}
	};

	const handleMouseLeave = (dropdownName) => {
		if (window.innerWidth > 1280) {
			toggleDropdown(dropdownName);
		}
	};

	const handleClick = (dropdownName) => {
		if (window.innerWidth <= 1280) {
			toggleDropdown(dropdownName);
		}
	};
	const dropdownRefs = {
		stojantiesiems: useRef(null),
		projektai: useRef(null),
		paslaugos: useRef(null),
	};

	useEffect(() => {
		Object.keys(dropdownStates).forEach((dropdownName) => {
			const ref = dropdownRefs[dropdownName].current;
			if (ref) {
				if (dropdownStates[dropdownName]) {
					const height = ref.scrollHeight;
					ref.style.maxHeight = `${height}px`;
				} else {
					ref.style.maxHeight = '0px';
				}
			}
		});
	}, [dropdownStates]);

	return (
		<nav className={`flex flex-1 justify-center ${className}`}>
			<ul className="max-xl:gap-0 max-xl:w-full flex flex-row gap-7 items-center justify-center max-xl:flex-col">
				<li
					className="navEl max-xl:pb-4 hover:opacity-60 duration-150"
					onClick={() => {
						closeHamburger();
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}}
				>
					<Link
						className="h-full flex items-center pr-1 text-black"
						to="/apie-mus/bendra-informacija"
					>
						APIE MUS
					</Link>
				</li>
				<li
					className="navEl"
					onMouseEnter={() => handleMouseEnter('stojantiesiems')}
					onMouseLeave={() => handleMouseLeave('stojantiesiems')}
					onClick={() => {
						handleClick('stojantiesiems');
					}}
				>
					<div className="flex flex-row gap-2 max-xl:pb-[12px] items-center">
						STOJANTIESIEMS
						<img
							src={chevron}
							alt="Chevron"
							className={`w-3 duration-150 transform ${
								dropdownStates.stojantiesiems ? 'rotate-180' : ''
							}`}
						/>
					</div>

					<ul
						ref={dropdownRefs.stojantiesiems}
						className={`max-xl:static max-xl:w-full absolute flex flex-col overflow-hidden bg-yellow w-[216px]  xl:rounded-b-xl shadow-md transition-max-height duration-150 ${
							dropdownStates.stojantiesiems
								? 'pointer-events-auto top-full max-xl:mb-4 max-xl:h-fit max-xl:mt-[4px] '
								: 'pointer-events-none top-full'
						}`}
					>
						{sortedApplicantData.map((item) => (
							<Link
								className="text-black"
								key={item.fields.index}
								to={`/stojantiesiems/${item.fields.route}`}
							>
								<li
									className={`dropDownEl ${
										dropdownStates.stojantiesiems ? '' : 'hidden'
									}`}
									onClick={() => {
										closeHamburger();
										window.scrollTo({ top: 0, behavior: 'smooth' });
									}}
								>
									{item.fields.title}
								</li>
							</Link>
						))}
					</ul>
				</li>
				<Link to="/profesijos">
					<li
						className="navEl max-xl:pb-4 hover:opacity-60 duration-150 text-black"
						onClick={() => {
							closeHamburger();
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}}
					>
						PROFESIJOS
					</li>
				</Link>
				<li
					className="navEl "
					onMouseEnter={() => handleMouseEnter('projektai')}
					onMouseLeave={() => handleMouseLeave('projektai')}
					onClick={() => {
						handleClick('projektai');
					}}
				>
					<div className="flex flex-row gap-2 max-xl:pb-[12px] pr-1">
						PROJEKTAI
						<img
							src={chevron}
							alt="Chevron"
							className={`w-3 duration-150 transform ${
								dropdownStates.projektai ? 'rotate-180' : ''
							}`}
						/>
					</div>

					<ul
						ref={dropdownRefs.projektai}
						className={`max-xl:static max-xl:w-full absolute flex flex-col overflow-hidden bg-yellow w-[168px] xl:rounded-b-xl shadow-md duration-150 ${
							dropdownStates.projektai
								? 'pointer-events-auto top-full max-xl:mb-4 max-xl:h-fit max-xl:mt-[4px]'
								: 'pointer-events-none top-full'
						} `}
					>
						<Link to="/projektai/tarptautiniai">
							<li
								className={`dropDownEl  ${
									dropdownStates.projektai ? '' : 'hidden'
								}`}
								onClick={() => {
									closeHamburger();
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}
							>
								Tarptautiniai
							</li>
						</Link>
						<Link to="/projektai/infrastrukturiniai">
							<li
								className={`dropDownEl ${
									dropdownStates.projektai ? '' : 'hidden'
								}`}
								onClick={() => {
									closeHamburger();
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}
							>
								Infrastruktūriniai
							</li>
						</Link>
						<Link to="/projektai/zmogiskuju-istekliu">
							<li
								className={`dropDownEl ${
									dropdownStates.projektai ? '' : 'hidden'
								}`}
								onClick={() => {
									closeHamburger();
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}
							>
								Žmogiškųjų išteklių
							</li>
						</Link>
					</ul>
				</li>
				<li
					className="navEl"
					onMouseEnter={() => handleMouseEnter('paslaugos')}
					onMouseLeave={() => handleMouseLeave('paslaugos')}
					onClick={() => {
						handleClick('paslaugos');
					}}
				>
					<div className="flex flex-row gap-2 max-xl:pb-[12px] items-center">
						PASLAUGOS
						<img
							src={chevron}
							alt="Chevron"
							className={`w-3 duration-150 transform ${
								dropdownStates.paslaugos ? 'rotate-180' : ''
							}`}
						/>
					</div>

					<ul
						ref={dropdownRefs.paslaugos}
						className={`max-xl:static max-xl:w-full absolute flex flex-col overflow-hidden bg-yellow w-[178px] xl:rounded-b-xl shadow-md transition-max-height duration-150 ${
							dropdownStates.paslaugos
								? 'pointer-events-auto top-full max-xl:mb-4 max-xl:h-fit max-xl:mt-[4px]'
								: 'pointer-events-none top-full'
						}`}
					>
						{sortedServiceData.map((item) => (
							<Link
								key={item.fields.route}
								to={`/paslaugos/${item.fields.route}`}
							>
								<li
									className={`dropDownEl ${
										dropdownStates.paslaugos ? '' : 'hidden'
									}`}
									onClick={() => {
										closeHamburger();
										window.scrollTo({ top: 0, behavior: 'smooth' });
									}}
								>
									{item.fields.title}
								</li>
							</Link>
						))}
					</ul>
				</li>
				<li
					className="navEl hover:opacity-60 duration-150"
					onClick={() => {
						closeHamburger();
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}}
				>
					<Link to="/kontaktai" className="text-black">
						KONTAKTAI
					</Link>
				</li>
			</ul>
		</nav>
	);
}
