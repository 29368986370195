import React from 'react';
import map from '../../../assets/svg/contacts/map-location-dot-solid.svg';
import phoneSvg from '../../../assets/svg/contacts/phone-solid.svg';
import emailSvg from '../../../assets/svg/contacts/envelope-solid.svg';
import ImageCarousel from '../../components/ImageCarousel';

export default function ServicesFeature({
	title,
	description,
	descriptionSecondParagraph,
	descriptionThirdParagraph,
	workingDays,
	workingFrom,
	workingTill,
	workInfo,
	email,
	phone,
	address,
	images,
	contactPerson,
}) {
	return (
		<section className="flex flex-col px-96 max-xl:px-32 max-sm:px-8 pr-64 flex flex-col gap-8 pt-40 mb-32 ">
			<div className="flex flex-col gap-8  w-full">
				<h1>{title}</h1>

				<p className="bg-white p-8 rounded-xl shadow-md">{description}</p>
				{descriptionSecondParagraph && (
					<p className="bg-white p-8 rounded-xl shadow-md">
						{descriptionSecondParagraph}
					</p>
				)}
				{descriptionThirdParagraph && (
					<p className="bg-white p-8 rounded-xl shadow-md">
						{descriptionThirdParagraph}
					</p>
				)}
			</div>
			<ImageCarousel images={images} />

			<div className="flex flex-col gap-8 flex-wrap bg-white p-8 rounded-xl shadow-md max-sm:p-4">
				<h3>Kontaktai</h3>
				<div className="flex flex-wrap max-xl:justify-between max-xl:gap-4 xl:gap-8">
					{contactPerson && (
						<span className="flex flex-row gap-4">
							<img className="w-6" src={emailSvg} alt={'Email'} />
							<p>{email}</p>
						</span>
					)}
					{email && (
						<span className="flex flex-row gap-4">
							<img className="w-6" src={emailSvg} alt={'Email'} />
							<p>{email}</p>
						</span>
					)}
					{phone && (
						<span className="flex flex-row gap-4 ">
							<img className="w-6" src={phoneSvg} alt={'Phone'} />
							<p>{phone}</p>
						</span>
					)}
					{address && (
						<span className="flex flex-row gap-4">
							<img className="w-6" src={map} alt="map" />
							<p>{address}</p>
						</span>
					)}
				</div>
				{workingDays && workingFrom && workingTill && (
					<div className="flex flex-col gap-4">
						<h3>Darbo laikas</h3>
						<p>
							{workingDays}&nbsp;
							<span className="font-bold">{workingFrom} val. </span>
							iki <span className="font-bold">{workingTill} val.</span>
						</p>

						{workInfo && <p>{workInfo}</p>}
					</div>
				)}
			</div>
		</section>
	);
}
