import React, { useState } from 'react';
import arrow from '../../assets/svg/card/arrow-right-solid.svg';
import { useNavigate } from 'react-router-dom';
import usePathConverter from '../customHooks/usePathConverter';

export default function NewsCard({
	heading,
	image,
	date,
	className,
	shortDescription,
}) {
	const navigate = useNavigate();
	const { convertTextToPath } = usePathConverter();
	const [isHovered, setHovered] = useState(false);

	const toggleHover = () => {
		setHovered(true);
	};
	const disableHover = () => {
		setHovered(false);
	};
	const handleClick = () => {
		const path = `${convertTextToPath(heading)}`;
		navigate(`/naujienos/${path}`);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<div
			className={`flex flex-col text-white overflow-hidden max-w-[446px] rounded-3xl shadow-2xl min-h-[576px] max-sm:w-full ${className}`}
		>
			<div className="relative w-full max-h-64">
				<img
					src={image}
					alt={'Cover'}
					className="w-full h-full object-scale-down"
				/>
			</div>
			<div className="w-full flex flex-col bg-black h-full p-8">
				<p className="text-sm text-neutral-300 pb-2">{date}</p>
				<p className="pb-4">{shortDescription}</p>
				<div className="mt-auto">
					<a
						onMouseEnter={toggleHover}
						onMouseLeave={disableHover}
						onClick={handleClick}
						className="flex gap-2 items-center cursor-pointer py-2 w-[84px]"
					>
						<p className="text-white">Plačiau</p>
						<img src={arrow} alt="Arrow to the right" />
					</a>
					<div
						className={`bg-green h-1 rounded-r-lg duration-300 ${
							isHovered ? 'w-20' : 'w-0'
						}`}
					></div>
				</div>
			</div>
		</div>
	);
}
