import React from 'react';
import UsefulLink from '../../components/UsefulLink';

export default function Departments({ data, departmentsData }) {
	const sortedDepartments = departmentsData.sort(
		(a, b) => a.fields.id - b.fields.id
	);

	return (
		<div className="mt-40 pb-32 pl-96 pr-32 max-xl:px-32 max-sm:px-16">
			<h1 className="mb-8">Padaliniai</h1>
			<div className="bg-white p-9 rounded-xl shadow-md mb-8">
				<img
					src={data[0].fields.departmentsImage.fields.file.url}
					alt="Padaliniai"
				/>
			</div>

			<ul className="flex gap-8 max-sm:gap-4 flex-wrap">
				{sortedDepartments.slice(1).map((department, index) => (
					<li key={index + 1}>
						<UsefulLink
							title={department.fields.name}
							url={department.fields.pageUrl}
						/>
					</li>
				))}
			</ul>
		</div>
	);
}
