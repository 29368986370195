import React, { useEffect, useState } from 'react';
import DocumentCard from '../../components/DocumentCard';
import useContentful from '../../customHooks/useContentful';

export default function Documents() {
	const [documentSets, setDocumentSets] = useState([]);
	const { getData } = useContentful('documentSet');

	useEffect(() => {
		getData().then((response) => {
			const sortedDocumentSets = response.sort((a, b) =>
				a.fields.title.localeCompare(b.fields.title)
			);
			setDocumentSets(sortedDocumentSets);
		});
	}, []);
	return (
		<section className="flex flex-col w-full mt-40 pb-32 pl-96 pr-32 max-xl:px-32 max-sm:px-16 ">
			<h1 className="mb-16">Dokumentai</h1>

			<div className="flex flex-col gap-8">
				{documentSets.map((documentSet, index) => (
					<DocumentCard
						key={index}
						title={documentSet.fields.title}
						files={documentSet.fields.documents}
						heading={documentSet.fields.paragraph}
						texts={documentSet.fields.documents.map((doc) => doc.fields.text)}
					/>
				))}
			</div>
		</section>
	);
}
