import React from 'react';

export default function Button({ btnText, className }) {
	return (
		<button
			className={`border-2 w-32 h-12 text-lg text-white rounded-lg hover:bg-green duration:150 ${className}`}
		>
			{btnText}
		</button>
	);
}
