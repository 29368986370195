import React from 'react';
import NewsCard from '../../components/NewsCard';
import { Link } from 'react-router-dom';

export default function NewsHome({ news }) {
	const sortedNews = news.sort(
		(a, b) => new Date(b.fields.date) - new Date(a.fields.date)
	);

	const limitedNews = sortedNews.slice(0, 4);
	return (
		<section className="py-16 flex flex-col items-center justify-center px-32 max-2xl:px-16 max-md:px-8 max-sm:w-full">
			<h2>Naujienos</h2>
			<div className="h-2 w-32 bg-green rounded-full mb-8 mt-4"></div>
			<div className="flex gap-16 justify-around flex-wrap max-xl:justify-center">
				{limitedNews.map((news, index) => (
					<NewsCard
						key={index}
						heading={news.fields.title}
						shortDescription={news.fields.shortDescription}
						image={news.fields.image.fields.file.url}
						date={news.fields.date}
					/>
				))}
			</div>
			{sortedNews.length > 4 && (
				<Link
					to={'/naujienos'}
					className="text-white px-4 py-2 rounded-full bg-cyan duration-150 hover:bg-green mt-8"
				>
					Daugiau naujienų
				</Link>
			)}
		</section>
	);
}
