import React from 'react';
import ReactMarkdown from 'react-markdown';

export default function ProfessionText({ heading, text, lineColor }) {
	return (
		<div className="flex flex-col max-xl:w-full max-xl:items-center bg-white p-8 rounded-xl shadow-md">
			<h2 className="mb-4">{heading}</h2>
			<div className={`bg-${lineColor} h-1 rounded-full w-32 mb-8`}></div>
			<ReactMarkdown
				className={
					'w-[512px] contentful-content text-justify max-md:text-left max-xl:w-auto'
				}
			>
				{text}
			</ReactMarkdown>
		</div>
	);
}
