import React, { useState } from 'react';
import chevron from '../../../assets/svg/nav/chevron-white.svg';
import { useNavigate } from 'react-router-dom';
import usePathConverter from '../../customHooks/usePathConverter';

export default function ProfessionCard({
	title,
	img,
	category,

	location,
}) {
	const [hovered, setHovered] = useState(false);

	const navigate = useNavigate();
	const { convertTextToPath } = usePathConverter();

	const handleClick = () => {
		const path = `${convertTextToPath(title)}`;
		navigate(`/profesijos/${path}`);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<div
			onClick={handleClick}
			className="flex flex-col border-2 w-96 rounded-xl shadow-md p-8 justify-center cursor-pointer w-64 select-none"
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<span className="flex items-center justify-center">
				<img
					src={img}
					alt={title}
					className="h-32 object-cover rounded-lg mb-4"
				/>
			</span>
			<p className="text-neutral-600">{category}</p>
			<h3>{title}</h3>

			<div className="mt-auto">
				<div className="h-0.5 my-4 bg-gray-200 rounded-full"></div>
				<p className="text-neutral-600">{location}</p>

				<div className="flex flex-row">
					<span className="flex flex-row gap-2 items-center">
						<h3 className="mr-2">Plačiau</h3>
						<span
							className={`bg-cyan px-2 py-2 mt-2 rounded-lg duration-150 ${
								hovered ? 'bg-green' : ''
							}`}
						>
							<img src={chevron} alt="Arrow" className="rotate-90 h-4 w-4" />
						</span>
					</span>
				</div>
			</div>
		</div>
	);
}
