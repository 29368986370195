import React, { useState, useCallback, useEffect, useRef } from 'react';
import chevron from '../../assets/svg/nav/chevron-white.svg';
export default function SideNav({
	onRenderChange,
	currentRenderState,
	navigationItems,
	changeRoute,
	title,
}) {
	const [hoveredItem, setHoveredItem] = useState(null);
	const [navTop, setNavTop] = useState(0);
	const navRef = useRef(null);
	const [isNavClosed, setNavState] = useState(true);

	const handleNavigationClick = (newRenderState, route) => {
		onRenderChange(newRenderState);
		setHoveredItem(null);
		if (changeRoute) {
			changeRoute(route);
		}
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setNavState(true);
	};

	const handleMouseEnter = useCallback((key) => {
		setHoveredItem(key);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setHoveredItem(null);
	}, []);

	const renderNavigationItems = () => {
		return navigationItems.map((item) => (
			<li
				key={item.key}
				onClick={() => handleNavigationClick(item.key, item.route)}
				onMouseEnter={() => handleMouseEnter(item.key)}
				onMouseLeave={handleMouseLeave}
				className="cursor-pointer relative w-fit pr-1"
			>
				{item.label}
				<span
					className={`absolute left-0 top-6 h-[4px] w-0 rounded-e-full transition-all duration-300 ${
						currentRenderState === item.key
							? 'w-full bg-cyan'
							: hoveredItem === item.key
							? 'w-full bg-green'
							: 'w-0'
					}`}
				></span>
			</li>
		));
	};

	useEffect(() => {
		const handleScroll = () => {
			if (!navRef.current) return;

			const footer = document.querySelector('footer');
			const navHeight = navRef.current.offsetHeight;
			const footerTop = footer.getBoundingClientRect().top + window.scrollY;
			const scrollY = window.scrollY;

			const maxScroll = footerTop - navHeight;

			if (scrollY >= maxScroll) {
				setNavTop(maxScroll - scrollY);
			} else {
				setNavTop(0);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<nav
			ref={navRef}
			style={{ top: `${navTop}px` }}
			className={`w-96 max-sm:w-[298px] select-none bg-white shadow-md z-40 fixed h-full transition-transform duration-300  ${
				isNavClosed
					? '-translate-x-80 max-sm:-translate-x-[280px]'
					: 'max-sm:w-screen'
			}`}
		>
			<div className="relative flex flex-col gap-8 pr-16 pt-40 pl-16 max-sm:px-8">
				<h2 className="text-cyan">{title}</h2>
				<ul className="flex flex-col gap-4 font-bold ml-4">
					{renderNavigationItems()}
				</ul>
				<div
					className={`absolute bg-cyan w-8 h-8 max-sm:w-6 max-sm:h-6 max-sm:-right-3 flex items-center justify-center rounded-full -right-4 mt-1 shadow-md cursor-pointer duration-150 ${
						isNavClosed
							? 'hover:xl:bg-green '
							: 'hover:xl:bg-red-500 max-sm:mr-12'
					}`}
					onClick={() => {
						setNavState(!isNavClosed);
					}}
				>
					<img
						src={chevron}
						alt="chevron"
						className={`-rotate-90 duration-150 max-sm:w-3 ${
							isNavClosed ? 'rotate-90' : ''
						}`}
					/>
				</div>
			</div>
		</nav>
	);
}
