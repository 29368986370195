import React, { useState } from 'react';

import chevron from '../../assets/svg/nav/chevron-down-solid.svg';

export default function Filter({ options, onRenderChange, svg, alt }) {
	const [expanded, setExpanded] = useState(false);
	const [currentRenderState, setRenderState] = useState(options[0]);

	const toggleExpand = () => {
		setExpanded(!expanded);
	};

	const handleOptionClick = (option) => {
		onRenderChange(option);
		setRenderState(option);
		setExpanded(false);
	};

	const filteredOptions = options.filter(
		(option) => option !== currentRenderState
	);
	return (
		<div className="select-none flex max-sm:justify-center z-30">
			<ul
				className="relative inline-flex flex-col items-center"
				onClick={toggleExpand}
			>
				<span className="z-20 inline-flex gap-4 bg-white px-8 py-4 rounded-full cursor-pointer items-center justify-center shadow-md">
					<img src={svg} alt={alt} className="w-6 h-6" />
					<p className="font-bold text-[24px]">{currentRenderState}</p>
					<img
						src={chevron}
						alt="Chevron"
						className={`w-4 h-4 transition-transform duration-150 transform ${
							expanded ? 'rotate-180' : ''
						}`}
					/>
				</span>

				<li
					className={`absolute top-1/2 bg-yellow w-full flex items-center justify-center rounded-b-2xl overflow-hidden  duration-150 shadow-md ${
						expanded ? 'h-auto ' : 'h-0'
					}`}
				>
					<ul className="flex flex-col text-[20px] font-bold w-full h-full items-center justify-center">
						{filteredOptions.map((option, index) => (
							<li
								key={index}
								className={`py-4 cursor-pointer w-full text-center hover:bg-green hover:text-white duration-150 ${
									index === 0 ? 'pt-12' : ''
								} `}
								onClick={() => handleOptionClick(option)}
							>
								{option}
							</li>
						))}
					</ul>
				</li>
			</ul>
		</div>
	);
}
