import React from 'react';
import ReactMarkdown from 'react-markdown';
import vesk from '../../assets/svg/vesk/text_big_3.svg';

export default function SuccessStries({ storiesData }) {
	return (
		<section className="w-2/3 flex flex-col gap-16 self-center max-w-[1024px] max-xl:w-full">
			{storiesData.map((story, index) => (
				<div
					key={index}
					className="flex flex-col bg-white rounded-xl shadow-md overflow-hidden p-4"
				>
					<div className="flex flex-wrap gap-8 relative">
						<span className="absolute right-0 max-[450px]:hidden">
							<img src={vesk} alt="VESK" className="w-24 max-sm:w-16" />
						</span>
						<span className="flex flex-col justify-center items-center w-full">
							<img
								src={story.fields.image.fields.file.url}
								alt="Nuotrauka"
								className=" w-64 rounded-full h-64 object-cover border-2 border-cyan max-sm:w-48 max-sm:h-48"
							/>
						</span>
					</div>

					<div className="p-8 flex flex-col w-full gap-8">
						<div className="relative">
							<ReactMarkdown className="text-lg flex flex-col gap-4 max-h-64 overflow-y-auto">
								{story.fields.description}
							</ReactMarkdown>
							<div className="absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-white"></div>
						</div>

						<div className="mt-auto border-t-2 w-full pt-2">
							<h3>{story.fields.studentName}</h3>
							<p>{story.fields.studentCourse}</p>
						</div>
					</div>
				</div>
			))}
		</section>
	);
}
