import React from 'react';
import veskIcon from '../../assets/svg/vesk/VESKicon.svg';

export default function Loading() {
	return (
		<div className="fixed inset-0 flex items-center justify-center z-50">
			<img src={veskIcon} alt="VESK" className="w-16 animate-ping"></img>
		</div>
	);
}
