import React from 'react';
import HeroHome from '../core/features/homePageFeatures/HeroHome';
import NewsHome from '../core/features/homePageFeatures/NewsHome.jsx';
import CardsHome from '../core/features/homePageFeatures/CardsHome.jsx';
import BenefitsCards from '../core/features/homePageFeatures/BenefitsCards.jsx';

export default function Home({ news, activeNews }) {
	return (
		<main>
			<HeroHome activeNews={activeNews} />
			<NewsHome news={news} />
			<CardsHome />
			<BenefitsCards />
		</main>
	);
}
