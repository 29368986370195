import React from 'react';
import ProfessionText from '../core/components/professions/ProfessionText';
import emailSvg from '../assets/svg/contacts/envelope-solid.svg';
import phoneSvg from '../assets/svg/contacts/phone-solid.svg';
import veskIcon from '../assets/svg/vesk/VESKicon.svg';

export default function ProfessionLP({
	title,
	about,
	audience,
	expectation,
	applicationUrl,
	howToApplyUrl,
	phone,
	email,
	img,
	benefits,
}) {
	return (
		<main className="flex flex-col bg-neutral-50 pb-32 ">
			<div className="pt-32 bg-white">
				<h1 className=" px-16 max-sm:px-8 mb-8 mt-8">{title}</h1>
				<div className="h-2 w-full bg-gradient-to-r from-green via-cyan to-yellow shadow-md"></div>
			</div>

			<div className="flex flex-row mb-32 mt-16 gap-8  px-16 max-sm:px-8 max-lg:flex-col max-lg:gap-8 max-lg:items-center">
				<span className="flex aspect-video justify-center w-1/2 max-lg:w-full">
					<img
						src={img}
						alt="Profesija"
						className="rounded-xl object-cover w-full "
					/>
				</span>

				<ul className="flex flex-col justify-around items-center gap-8 flex-wrap w-1/2 max-lg:gap-8 max-lg:w-full">
					{benefits &&
						benefits.map((benefits, index) => (
							<li
								key={index}
								className="flex p-4 gap-4 bg-white rounded-lg shadow-md w-[512px] max-lg:w-full"
							>
								<img src={veskIcon} alt="VESK" className="w-6" />
								<h3>{benefits}</h3>
							</li>
						))}
				</ul>
			</div>
			<div className="justify-between max-xl:justify-center flex flex-wrap gap-32 pb-32 px-16 max-sm:px-8">
				<ProfessionText
					heading={'Apie programą'}
					text={about}
					lineColor={'green'}
				/>
				<ProfessionText
					heading={'Kas laukia'}
					text={expectation}
					lineColor={'cyan'}
				/>
				<ProfessionText
					heading={'Kam ši profesija'}
					text={audience}
					lineColor={'yellow'}
				/>
			</div>
			<div className="flex fex-row py-32  items-center justify-center bg-white max-xl:flex-col  px-16 max-sm:px-8">
				<h2 className="text-center flex items-center max-xl:mb-8">
					Nori mokytis?
				</h2>
				<div className="w-0.5 h-28 bg-neutral-100 rounded-full mx-16 max-xl:hidden"></div>
				<span className="flex flex-col gap-4 w-1/3 max-md:w-full">
					<a
						rel="noreferrer"
						href={applicationUrl}
						target="_blank"
						className="py-4 bg-cyan text-center text-xl shadow-md rounded-lg text-white duration-150 hover:bg-green"
					>
						Teikti prašymą
					</a>
					<a
						rel="noreferrer"
						href={howToApplyUrl}
						target="_blank"
						className="text-center text-lg underline text-burgundy hover:text-forestGreen duration-150"
					>
						Kaip užpildyti prašymą?
					</a>
				</span>
			</div>
			<div className="pt-32  px-16 max-sm:px-8">
				<h2 className="text-center mb-4">Nori sužinoti daugiau?</h2>
				<h2 className="text-center mb-8 text-cyan">Susisiekime!</h2>
				<div className="flex fex-row items-center justify-center gap-8 flex-wrap">
					<span className="flex gap-2">
						<img src={emailSvg} alt="Email" className="w-6 max-xl:w-4" />
						<p className="text-xl max-xl:text-lg">{email}</p>
					</span>
					<span className="flex gap-2 ">
						<img src={phoneSvg} alt="Phone" className="w-6 max-xl:w-4" />
						<p className="text-xl max-xl:text-lg">{phone}</p>
					</span>
				</div>
			</div>
		</main>
	);
}
