import { useCallback } from 'react';

const usePathConverter = () => {
	const lithuanianToEnglishMap = {
		ą: 'a',
		č: 'c',
		ę: 'e',
		ė: 'e',
		į: 'i',
		š: 's',
		ų: 'u',
		ū: 'u',
		ž: 'z',
		Ą: 'A',
		Č: 'C',
		Ę: 'E',
		Ė: 'E',
		Į: 'I',
		Š: 'S',
		Ų: 'U',
		Ū: 'U',
		Ž: 'Z',
	};

	const convertLithuanianChars = useCallback(
		(str) => {
			return str
				.split('')
				.map((char) => lithuanianToEnglishMap[char] || char)
				.join('');
		},
		[lithuanianToEnglishMap]
	);

	const convertTextToPath = useCallback(
		(text) => {
			const convertedText = convertLithuanianChars(text);
			return convertedText
				.replace(/[^\w\s-]/g, '')
				.replace(/\s+/g, '-')
				.toLowerCase();
		},
		[convertLithuanianChars]
	);

	return { convertTextToPath };
};

export default usePathConverter;
