import React from 'react';

export default function Table({ headers, data }) {
	return (
		<table className="w-full bg-white rounded-t-xl shadow-md ">
			<thead className="w-full justify-between ">
				<tr>
					{headers.map((header, index) => (
						<th scope="col" className="text-start py-8 pl-8" key={index}>
							<h4 className="text-lg">{header}</h4>
						</th>
					))}
				</tr>
			</thead>
			<tbody className="[&>*:nth-child(odd)]:bg-neutral-200">
				{data.map((row, rowIndex) => (
					<tr key={rowIndex}>
						{headers.map((header, colIndex) => (
							<td key={colIndex} className="px-8 py-4 ">
								{Array.isArray(row[header])
									? row[header].join(', ')
									: row[header]}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}
