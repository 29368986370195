import React from 'react';
import SvgLink from '../components/SvgLink';
import veskBigTextSide from '../../assets/svg/vesk/VESKbigTextSide.svg';
import fbIcon from '../../assets/svg/socialMedia/facebook.svg';
import igIcon from '../../assets/svg/socialMedia/instagram.svg';
import liIcon from '../../assets/svg/socialMedia/linkedin.svg';
import ytIcon from '../../assets/svg/socialMedia/youtube.svg';
import FooterNav from '../components/footer/FooterNav';

export default function Footer({ footerPages }) {
	const mokiniamsLinks = footerPages
		.filter((page) => page.fields.group === 'mokiniams')
		.map((page) => ({
			text: page.fields.title,
			url: page.fields.url,
			title: page.fields.title,
		}));

	const aktualuLinks = footerPages
		.filter((page) => page.fields.group === 'aktualu')
		.map((page) => ({
			text: page.fields.title,
			url: page.fields.url,
			title: page.fields.title,
		}));
	const svietimasLinks = footerPages
		.filter((page) => page.fields.group === 'svietimas')
		.map((page) => ({
			text: page.fields.title,
			url: page.fields.url,
			title: page.fields.title,
		}));
	return (
		<footer className="bg-black flex flex-col py-8 px-16 gap-8 select-none max-sm:px-8 relative z-40">
			<div className="w-full flex justify-center content-center">
				<img src={veskBigTextSide} alt="VESK Logo" className="w-96" />
			</div>
			<nav className="flex flex-row gap-4 justify-center content-center">
				<SvgLink
					href={'https://www.linkedin.com/company/vesk/'}
					image={liIcon}
					imgAlt={'LinkedIn icon'}
				/>
				<SvgLink
					href={'https://www.facebook.com/vesk.lt?locale=lt_LT'}
					image={fbIcon}
					imgAlt={'Facebook icon'}
				/>
				<SvgLink
					href={'https://www.instagram.com/vesk_lt/'}
					image={igIcon}
					imgAlt={'Instagram icon'}
				/>
				<SvgLink
					href={'https://www.youtube.com/@vesk_lt'}
					image={ytIcon}
					imgAlt={'Youtube icon'}
				/>
			</nav>
			<nav className="flex flex-row justify-center gap-32 max-md:flex-col max-md:gap-8">
				<FooterNav
					title={'Mokiniams'}
					links={mokiniamsLinks}
					bgColor={'green'}
				/>
				<FooterNav
					title={'Informacija'}
					links={svietimasLinks}
					bgColor={'cyan'}
				/>
				<FooterNav title={'Aktualu'} links={aktualuLinks} bgColor={'yellow'} />
			</nav>
		</footer>
	);
}
