import React from 'react';
import { useNavigate } from 'react-router-dom';
import usePathConverter from '../customHooks/usePathConverter';
export default function SmallCard({ icon, alt, text }) {
	const navigate = useNavigate();
	const { convertTextToPath } = usePathConverter();

	const handleClick = () => {
		const path = `${convertTextToPath(text)}`;
		navigate(`${path}`);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<div className="flex flex-col justify-between bg-white w-[296px] h-[228px] shadow-lg rounded-lg">
			<span className="px-[64px] py-[32px] flex flex-col items-center gap-4">
				<img src={icon} alt={alt} className="w-8 h-8" />
				<p className="text-center flex justify-center items-center text-xl font-bold ">
					{text}
				</p>
			</span>

			<button
				onClick={handleClick}
				className="py-[16px] flex justify-center items-center gap-2 bg-yellow rounded-b-lg  duration-150 hover:bg-green hover:text-white"
			>
				<p className="font-bold text-xl">Plačiau</p>
			</button>
		</div>
	);
}
