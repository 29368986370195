import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideNav from '../core/features/SideNav';
import ProjectFeature from '../core/features/projects/ProjectFeature';

export default function Projects({ state, projectData }) {
	const [isRender, setRender] = useState(state);
	const navigate = useNavigate();

	useEffect(() => {
		setRender(state);
	}, [state]);

	const handleRenderChange = (newRenderState) => {
		setRender(newRenderState);
	};

	const changeRouteFunction = (route) => {
		navigate(route);
	};

	const navigationItems = [
		{
			key: 'tarptautiniai',
			label: 'Tarptautiniai projektai',
			route: '/projektai/tarptautiniai',
		},
		{
			key: 'infrastrukturiniai',
			label: 'Infrastruktūriniai projektai',
			route: '/projektai/infrastrukturiniai',
		},
		{
			key: 'zmogiskuju-istekliu',
			label: 'Žmogiskųjų išteklių projektai',
			route: '/projektai/zmogiskuju-istekliu',
		},
	];

	return (
		<main className="flex bg-neutral-50 min-h-screen">
			<SideNav
				title={'Projektai'}
				onRenderChange={handleRenderChange}
				currentRenderState={isRender}
				navigationItems={navigationItems}
				changeRoute={changeRouteFunction}
			/>
			<ProjectFeature
				key={isRender}
				type={isRender}
				title={navigationItems.find((item) => item.key === isRender).label}
				projectData={projectData}
			/>
		</main>
	);
}
