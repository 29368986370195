import React from 'react';

export default function SvgLink({ href, image, imgAlt }) {
  return (
    <a
      href={href}
      target='_blank'
      rel='noreferrer'
      className='w-6 h-6 opacity-80 hover:opacity-100 duration-150'
    >
      <img src={image} alt={imgAlt} className='w-full h-full ' />
    </a>
  );
}
