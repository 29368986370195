import React from 'react';
import phoneSvg from '../../../assets/svg/contacts/phone-solid.svg';
import emailSvg from '../../../assets/svg/contacts/envelope-solid.svg';

export default function EmployeeCard({ name, photo, type, email, phone }) {
	return (
		<div className="flex flex-col gap-8 items-center rounded-lg bg-white border shadow-md w-80 p-8 max-sm:w-full">
			<span className="w-32 h-32  rounded-full flex overflow-hidden">
				<img src={photo} alt={'Portrait'} className="object-cover w-full" />
			</span>

			<div className="flex flex-col gap-4 max-w-[256px]">
				<h3>{name}</h3>
				<h4 className="text-cyan">{type}</h4>
				<div className="flex gap-2">
					<img src={emailSvg} alt="Email" className="w-4" />
					<p>{email}</p>
				</div>
				{phone && (
					<div className="flex gap-2">
						<img src={phoneSvg} alt="Phone" className="w-4" />
						<p>{phone}</p>
					</div>
				)}
			</div>
		</div>
	);
}
