import React from 'react';

export default function NavContacts({ img, imgAlt, text }) {
  return (
    <span className='navSpan'>
      <img src={img} alt={imgAlt} className='w-6' />
      <p className='flex items-center justify-center select-text text-lg'>
        {text}
      </p>
    </span>
  );
}
