import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

export default function CookieConsent() {
	const [cookieState, setCookieState] = useState('');
	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		const cookieConsent = document.cookie
			.split('; ')
			.find((row) => row.startsWith('cookieConsent='));
		const declineSession = sessionStorage.getItem('cookieConsentDeclined');

		if (cookieConsent && cookieConsent.split('=')[1] === 'accepted') {
			setCookieState('accepted');
			initializeGA();
		} else if (declineSession) {
			setCookieState('declined');
		}
		setIsInitialized(true);
	}, []);

	const initializeGA = () => {
		ReactGA.initialize('G-FX3WKZL9BF');
		ReactGA.send('pageview');
	};

	const setConsentCookie = (hours) => {
		const expires = new Date(Date.now() + hours * 60 * 60 * 1000).toUTCString();
		document.cookie = `cookieConsent=accepted; expires=${expires}; path=/`;
	};

	const handleAccept = () => {
		setConsentCookie(24);
		setCookieState('accepted');
		initializeGA();
	};

	const handleDecline = () => {
		setCookieState('declined');
		sessionStorage.setItem('cookieConsentDeclined', true);
	};

	if (
		!isInitialized ||
		cookieState === 'accepted' ||
		cookieState === 'declined'
	) {
		return null;
	}

	return (
		<div className="flex flex-col fixed bottom-0 bg-white px-16 gap-4 py-8 z-50 border-t-2 max-sm:px-8 w-full">
			<h3 className="max-sm:text-center">
				Naudojame slapukus, kad pagerintume jūsų patirtį.
			</h3>
			<p className="max-sm:text-center">
				Mūsų svetainėje naudojami „Google Analytics“ slapukai, kuriais renkami
				anoniminiai duomenys, pavyzdžiui, puslapių peržiūros, kad galėtume
				tobulinti turinį ir užtikrinti geresnę naudotojų patirtį.
			</p>
			<div className="flex gap-8 max-sm:justify-center">
				<button
					onClick={handleAccept}
					className="px-4 py-2 text-white bg-cyan rounded-lg hover:bg-green duration-150"
				>
					Sutinku
				</button>
				<button
					onClick={handleDecline}
					className="px-4 py-2 bg-neutral-100 rounded-lg hover:bg-neutral-200 duration-150"
				>
					Nesutinku
				</button>
			</div>
		</div>
	);
}
