import React from 'react';

export default function ContactElement({ img, alt, bigText, smallText }) {
  return (
    <div className='flex flex-row gap-8 items-center'>
      <img src={img} alt={alt} className='w-10 h-10' />
      <span>
        <p className='text-gray-500'>{smallText}</p>
        <h3>{bigText}</h3>
      </span>
    </div>
  );
}
