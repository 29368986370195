import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
export default function ExpandingParagraph({ title, text, buttonUrl }) {
	const [isOpened, setOpened] = useState(false);
	const [isHovered, setHovered] = useState(false);

	return (
		<div
			className="flex items-start cursor-pointer bg-white p-8 rounded-xl shadow-md"
			onClick={() => {
				setOpened(!isOpened);
			}}
			onMouseEnter={() => {
				setHovered(true);
			}}
			onMouseLeave={() => {
				setHovered(false);
			}}
		>
			<div className="pr-4 ">
				<h2
					className={`${isHovered ? 'text-cyan' : ''} ${
						isOpened ? 'text-cyan' : ''
					}`}
				>
					{title}
				</h2>
				<span
					className={`${isOpened ? '' : 'hidden'} flex flex-col mt-8 gap-4`}
				>
					<ReactMarkdown
						className={`text-slate-600 flex flex-col gap-4 reactMarkdown`}
					>
						{text}
					</ReactMarkdown>
					{buttonUrl && (
						<a
							href={buttonUrl}
							target="_blank"
							rel="noopener noreferrer"
							className="px-4 py-2 w-fit bg-cyan text-white rounded-lg shadow-md font-bold tracking-wider hover:bg-green duration-150"
						>
							Plačiau
						</a>
					)}
				</span>
			</div>
			<span className="ml-auto ">
				<p className="text-4xl ">{isOpened ? '-' : '+'}</p>
			</span>
		</div>
	);
}
