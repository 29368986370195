import React from 'react';
import VESKicon from '../../../assets/svg/vesk/VESKicon.svg';
import ImageCarousel from '../../components/ImageCarousel';
import ReactMarkdown from 'react-markdown';

export default function AboutUs({ data }) {
	return (
		<section className="w-full relative mt-40 pl-96 pr-32 max-xl:px-32 max-sm:px-16 pb-32">
			<div className="flex gap-8 mb-8">
				<h1>{data[0].fields.title}</h1>
				<img src={VESKicon} alt="VESK" className="w-16 -translate-y-2" />
			</div>

			<div className="flex flex-col gap-8 ">
				<ReactMarkdown className="bg-white p-8 flex flex-col gap-4 rounded-xl shadow-md">
					{data[0].fields.boldParagraph}
				</ReactMarkdown>
				<ImageCarousel
					images={data[0].fields.images.map((image) => image.fields.file.url)}
				/>
				<ReactMarkdown className="bg-white p-8 flex flex-col gap-4 rounded-xl shadow-md">
					{data[0].fields.description}
				</ReactMarkdown>
				{data[0].fields.aditionalInformation && (
					<ReactMarkdown className="bg-white p-8 flex flex-col gap-4 rounded-xl shadow-md">
						{data[0].fields.aditionalInformation}
					</ReactMarkdown>
				)}
			</div>
		</section>
	);
}
