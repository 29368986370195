import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideNav from '../core/features/SideNav';
import AboutUs from '../core/features/about/AboutUs';
import Departments from '../core/features/about/Departments';
import Structure from '../core/features/about/Structure';
import Documents from '../core/features/about/Documents';
import AdministrativeLegal from '../core/features/about/AdministrativeLegal';

export default function About({ state, data, ALData, departmentsData }) {
	const [isRender, setRender] = useState(state);
	const navigate = useNavigate();

	useEffect(() => {
		setRender(state);
	}, [state]);

	const handleRenderChange = (newRenderState) => {
		setRender(newRenderState);
	};
	const changeRouteFunction = (route) => {
		navigate(route);
	};

	const navigationItems = [
		{
			key: 'about',
			label: 'Bendra informacija',
			route: '/apie-mus/bendra-informacija',
		},
		{ key: 'departments', label: 'Padaliniai', route: '/apie-mus/padaliniai' },
		{ key: 'structure', label: 'Struktūra', route: '/apie-mus/struktura' },
		{
			key: 'administrative',
			label: 'Administracinė informacija',
			route: '/apie-mus/administracine-informacija',
		},
		{
			key: 'legal',
			label: 'Teisinė informacija',
			route: '/apie-mus/teisine-informacija',
		},
		{ key: 'documents', label: 'Dokumentai', route: '/apie-mus/dokumentai' },
	];

	const administrativeData = ALData.filter(
		(item) => item.fields.type === 'administrative'
	);
	const legalData = ALData.filter((item) => item.fields.type === 'legal');

	function renderComponent() {
		switch (isRender) {
			case 'about':
				return <AboutUs data={data} />;
			case 'departments':
				return <Departments data={data} departmentsData={departmentsData} />;
			case 'structure':
				return <Structure data={data} />;
			case 'legal':
				return (
					<AdministrativeLegal
						title={legalData[0].fields.title}
						description={legalData[0].fields.description}
						mission={legalData[0].fields.mission}
						vision={legalData[0].fields.vision}
						values={legalData[0].fields.values}
						documents={legalData[0].fields.documents}
					/>
				);
			case 'administrative':
				return (
					<AdministrativeLegal
						title={administrativeData[0].fields.title}
						description={administrativeData[0].fields.description}
						mission={administrativeData[0].fields.mission}
						vision={administrativeData[0].fields.vision}
						values={administrativeData[0].fields.values}
						documents={administrativeData[0].fields.documents}
					/>
				);

			case 'documents':
				return <Documents />;
			default:
				return null;
		}
	}
	return (
		<main className="flex gap-32 bg-neutral-50 min-h-screen">
			<SideNav
				title={'Apie mus'}
				onRenderChange={handleRenderChange}
				currentRenderState={isRender}
				navigationItems={navigationItems}
				changeRoute={changeRouteFunction}
			/>
			{renderComponent()}
		</main>
	);
}
