import React from 'react';

export default function Structure({ data }) {
	return (
		<div className="mt-40 pb-32 pl-96 pr-32 max-xl:px-32 max-sm:px-16">
			<h1 className="mb-8">Struktūra</h1>
			<div className="bg-white p-8 rounded-xl shadow-md max-sm:p-2 max-lg:p-4">
				<img
					src={data[0].fields.structureImage.fields.file.url}
					alt="Struktura"
				/>
			</div>
		</div>
	);
}
