import React from 'react';
import ReactMarkdown from 'react-markdown';
import DocumentCard from '../core/components/DocumentCard';
import ExpandingParagraph from '../core/components/FooterPages/ExpandingParagraph';
import ImageCarousel from '../core/components/ImageCarousel';
import SuccessStries from '../core/features/SuccessStries';

export default function FooterPage({
	title,
	images,
	mainParagraph,
	expandingParagraph,
	documents,
	storiesData,
}) {
	console.log(storiesData);
	return (
		<main className="pb-32 pt-40 flex flex-col gap-8 px-40 max-2xl:px-16 max-sm:px-8 bg-neutral-50">
			<h1 className="text-center">{title}</h1>
			{images.length > 0 && <ImageCarousel images={images} />}
			{mainParagraph && (
				<ReactMarkdown className="bg-white p-8 rounded-xl shadow-md flex flex-col gap-4 contentful-content">
					{mainParagraph}
				</ReactMarkdown>
			)}
			{storiesData && <SuccessStries storiesData={storiesData} />}
			{expandingParagraph && (
				<div className="mt-8 flex flex-col gap-16">
					{expandingParagraph.map((item, index) => (
						<ExpandingParagraph
							key={index}
							title={item.fields.title}
							text={item.fields.paragraph}
							buttonUrl={item.fields.buttonUrl}
						/>
					))}
				</div>
			)}

			{documents && (
				<div>
					<DocumentCard title={'Dokumentai'} files={documents} />
				</div>
			)}
		</main>
	);
}
