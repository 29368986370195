import React from 'react';
import ReactMarkdown from 'react-markdown';

export default function NewsLP({ title, date, description, img }) {
	return (
		<main className="pb-32 pt-40 flex flex-col gap-8 px-40 max-2xl:px-16 max-sm:px-8 bg-neutral-50">
			<h1>{title}</h1>
			<div className="flex gap-2 p-4 rounded-full bg-white shadow-md w-fit">
				<p className="font-bold">Skelbimo data:</p>
				<p>{date}</p>
			</div>
			<div className="flex justify-center">
				<img
					src={img}
					alt="Naujiena"
					className="xl:max-w-[512px] max-xl:self-center object-cover"
				/>
			</div>

			<ReactMarkdown className="bg-white flex flex-col gap-8 p-8 rounded-xl shadow-md">
				{description}
			</ReactMarkdown>
		</main>
	);
}
