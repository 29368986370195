import React from 'react';

export default function MediumCard({ img, alt, title, paragraph }) {
	return (
		<div className="p-8 flex flex-col items-center shadow-md gap-4 bg-gray-100">
			<span className="rounded-full bg-yellow p-3">
				<img src={img} alt={alt} className="w-8 h-8" />
			</span>
			<h3>{title}</h3>
			<p className="text-center">{paragraph}</p>
		</div>
	);
}
