import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import veskLogo from '../../../assets/svg/vesk/VESKlogo.svg';
import phone from '../../../assets/svg/contacts/phone-outline.svg';
import email from '../../../assets/svg/contacts/envelope-regular.svg';
import NavContacts from '../../components/header/NavContacts';
import Nav from './Nav';

export default function Header({ department, applicantData, serviceData }) {
	const [isScrolled, setScrolled] = useState(false);
	const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		setScrolled(scrollPosition > 0);
	};
	const handleHamburger = () => {
		setIsHamburgerOpen(!isHamburgerOpen);
	};

	const closeHamburger = () => {
		setIsHamburgerOpen(false);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<header
			className={` bg-white flex flex-row px-16 select-none font-semibold text-base tracking-wider z-50 fixed w-full transition-max-height max-sm:px-8  duration-100 ${
				isScrolled ? 'shadow-md max-h-[80px]' : ' max-h-[96px]'
			}`}
		>
			<Link
				to="/"
				className="w-32 cursor-pointer flex items-center py-4"
				onClick={closeHamburger}
			>
				<img
					src={veskLogo}
					alt="VESK Logo"
					className="	"
					onClick={() => {
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}}
				/>
			</Link>
			<Nav
				className={`max-xl:absolute max-xl:w-screen max-xl:top-full max-xl:bg-white max-xl:py-4 max-xl:shadow-md duration-150 ${
					isHamburgerOpen
						? 'max-xl:translate-x-[-64px] max-sm:translate-x-[-32px]'
						: 'max-xl:translate-x-[1280px]'
				}`}
				closeHamburger={closeHamburger}
				applicantData={applicantData}
				serviceData={serviceData}
			/>

			<div className="flex flex-row gap-8 ml-auto max-2xl:hidden">
				{department && (
					<>
						<NavContacts
							img={email}
							imgAlt={'Email'}
							text={department.fields.email}
						/>
						<NavContacts
							img={phone}
							imgAlt={'Phone'}
							text={department.fields.phone}
						/>
					</>
				)}
			</div>
			<div
				className="xl:hidden flex flex-col gap-1 cursor-pointer justify-center ml-auto"
				onClick={handleHamburger}
			>
				<span
					className={`bg-black h-1 w-8 duration-150 ${
						isHamburgerOpen ? 'rotate-45 translate-y-2' : ''
					}`}
				></span>
				<span
					className={`bg-black h-1 w-8 duration-150 ${
						isHamburgerOpen ? 'scale-0' : ''
					}`}
				></span>
				<span
					className={`bg-black h-1 w-8 duration-150 ${
						isHamburgerOpen ? '-rotate-45 -translate-y-2' : ''
					}`}
				></span>
			</div>
		</header>
	);
}
