import React from 'react';
import NewsCard from '../core/components/NewsCard';

export default function NewsPage({ news }) {
	const sortedNews = news.sort(
		(a, b) => new Date(b.fields.date) - new Date(a.fields.date)
	);
	console.log(sortedNews);

	return (
		<main className="pb-32 pt-40 flex flex-col gap-8 px-40 max-2xl:px-16 max-sm:px-8 bg-neutral-50">
			<h1 className="self-center mb-8">Naujienos</h1>
			<div className="flex gap-16 justify-around flex-wrap max-xl:justify-center">
				{sortedNews.map((news, index) => (
					<NewsCard
						key={index}
						heading={news.fields.title}
						shortDescription={news.fields.shortDescription}
						image={news.fields.image.fields.file.url}
						date={news.fields.date}
					/>
				))}
			</div>
		</main>
	);
}
