import React from 'react';
import ContactsMap from '../core/features/contacts/ContactsMap';

export default function Contacts({ employees, departments }) {
	return (
		<main>
			<ContactsMap employees={employees} departments={departments} />
		</main>
	);
}
