import React from 'react';
import veskIcon from '../../assets/svg/vesk/VESKicon.svg';
import usePathConverter from '../customHooks/usePathConverter';
import { Link } from 'react-router-dom';

export default function UsefulLink({ title, url }) {
	const { convertTextToPath } = usePathConverter();
	return (
		<div className="flex flex-row px-4 py-2 gap-4 border-2 rounded-xl w-fit cursor-pointer shadow-sm  hover:border-green duration-150">
			<span className="flex items-center">
				<img src={veskIcon} alt="VESK" className="min-w-6" />
			</span>
			{url ? (
				<a href={url} target="_blank" className="text-black">
					<h3>{title}</h3>
				</a>
			) : (
				<Link
					to={convertTextToPath(title)}
					onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
					className="opacity-80 cursor-pointer hover:opacity-100 duration:150 text-black"
				>
					<h3>{title}</h3>
				</Link>
			)}
		</div>
	);
}
