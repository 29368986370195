import React from 'react';
import SmallCard from '../../components/SmallCard';
import landmarkIcon from '../../../assets/svg/card/landmark-solid.svg';
import schoolIcon from '../../../assets/svg/card/school-solid.svg';
import specialNeedsIcon from '../../../assets/svg/card/people-group-solid.svg';
import suitIcon from '../../../assets/svg/card/user-tie-solid.svg';
import chartIcon from '../../../assets/svg/card/chart-line-solid.svg';
import graduateIcon from '../../../assets/svg/card/user-graduate-solid.svg';

export default function CardsHome() {
	return (
		<section className="bg-gray-100 py-16 px-32 max-2xl:px-16 max-md:px-8 flex flex-col items-center">
			<h2 className="text-center">Švietimas ir profesinis tobulėjimas</h2>
			<div className="h-2 w-32 bg-cyan rounded-full mb-8 mt-4"></div>
			<div className="gap-16 grid grid-cols-3 grid-rows-2 max-xl:grid-cols-2 max-xl:grid-rows-3 max-lg:grid-cols-1 max-lg:grid-rows-6 place-items-center">
				<SmallCard
					icon={landmarkIcon}
					alt={'Landmark Icon'}
					text={'UŽT REMIAMAS MOKSLAS'}
				/>
				<SmallCard
					icon={graduateIcon}
					alt={'Graduate Icon'}
					text={'SUAUGUSIŲJŲ MOKSLAS'}
				/>
				<SmallCard
					icon={specialNeedsIcon}
					alt={'Special Needs Icon'}
					text={'TURINTIEMS SUP'}
					btnText={''}
				/>
				<SmallCard
					icon={chartIcon}
					alt={'Chart Icon'}
					text={'KVALIFIKACIJOS TOBULINIMAS'}
				/>
				<SmallCard
					icon={schoolIcon}
					alt={'School Icon'}
					text={'GIMNAZIJA'}
					btnText={''}
				/>
				<SmallCard
					icon={suitIcon}
					alt={'Suit Icon'}
					text={'PROFESINĖ REABILITACIJA'}
				/>
			</div>
		</section>
	);
}
