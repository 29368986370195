import { createClient } from 'contentful';
import { useState } from 'react';

const useContentful = (contentType) => {
	const client = createClient({
		space: process.env.REACT_APP_SPACE_ID,
		accessToken: process.env.REACT_APP_ACCESS_TOKEN,
		host: '',
	});

	const [loading, setLoading] = useState(true);

	const getData = async () => {
		try {
			const entries = await client.getEntries({
				content_type: contentType,
				select: 'fields',
			});
			setLoading(false);
			return entries.items;
		} catch (error) {
			console.log(`Error fetching: ${error.message}`);
			setLoading(false);
			return [];
		}
	};

	return { getData, loading };
};

export default useContentful;
