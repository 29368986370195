import React, { useState } from 'react';
import DocSvg from '../../assets/svg/documentCard/file-regular.svg';

export default function DocumentCard({
	title,
	files,
	className,
	heading,
	texts,
}) {
	const [isClicked, setClicked] = useState(false);

	return (
		<div
			className={`flex flex-col bg-white text-black px-8 py-8 shadow-md rounded-xl ${className}`}
		>
			<div className="flex flex-row justify-between w-full items-center">
				<h3>{title}</h3>
				<span
					className={`bg-cyan text-white cursor-pointer w-16 h-16 flex items-center justify-center select-none rounded-lg max-2xl:w-12 max-2xl:h-12 max-lg:w-8 max-lg:h-8 hover:bg-green duration-150 ${
						isClicked ? 'bg-red-600' : ''
					}`}
					onClick={() => setClicked(!isClicked)}
				>
					<p
						className={`text-4xl max-lg:text-2xl text-white tracking-normal ${
							isClicked ? 'translate-y-[-2px] ' : ''
						}`}
					>
						{isClicked ? '-' : '+'}
					</p>
				</span>
			</div>
			{isClicked && (
				<ul className="flex flex-col gap-8 mt-8 pl-8 select-none border-t-2 pt-8">
					{heading && <h4 className="text-xl">{heading}</h4>}

					{files.map((file, index) => (
						<li key={index} className="flex flex-col gap-4">
							{texts && texts[index] && (
								<p className="text-neutral-500 text-sm">{texts[index]}</p>
							)}
							<span className="flex gap-4">
								<img src={DocSvg} alt="Document Svg" className="w-6 h-6" />
								<a
									href={file.fields.file.fields.file.url}
									rel="noreferrer"
									target="_blank"
									className="text-black"
								>
									{file.fields.name}
								</a>
							</span>
						</li>
					))}
				</ul>
			)}
		</div>
	);
}
