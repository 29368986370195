import React, { useEffect, useState } from 'react';
import video from '../../../assets/video/VESK_v01.mp4';
import Button from '../../components/Button';
import veskLogo from '../../../assets/svg/vesk/VESKlogo-white.svg';
import veskBigLogo from '../../../assets/svg/vesk/VESKbigTextBottom.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import usePathConverter from '../../customHooks/usePathConverter';

export default function HeroHome({ activeNews }) {
	const [isHovered, setHovered] = useState(false);
	const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1920);

	useEffect(() => {
		const handleResize = () => {
			setIsWideScreen(window.innerWidth > 1920);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const toggleDim = () => {
		setHovered(true);
	};
	const disableDim = () => {
		setHovered(false);
	};

	const navigate = useNavigate();
	const { convertTextToPath } = usePathConverter();

	const handleNav = () => {
		const path = `${convertTextToPath(activeNews.fields.title)}`;
		navigate(`${path}`);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<section
			className="relative h-screen overflow-hidden pt-[96px]"
			onMouseEnter={toggleDim}
			onMouseLeave={disableDim}
		>
			{activeNews && activeNews.fields.changeVideo ? (
				<div className="cursor-pointer hidden" onClick={handleNav}>
					<img
						src={activeNews.fields.image.fields.file.url}
						className="absolute h-full w-full object-cover"
						alt="Active news"
					/>
				</div>
			) : (
				<>
					<video
						src={video}
						className="absolute h-full w-full object-cover"
						loop
						muted
						autoPlay
						playsInline
						preload="metadata"
					/>

					<div
						className={`absolute h-full w-full z-30 bg-black max-xl:hidden ${
							isHovered ? 'opacity-50' : 'opacity-0'
						} duration-200`}
					></div>

					<h1
						className={`absolute z-40 text-white top-[180px] font-medium text-6xl max-xl:hidden ${
							isHovered
								? 'translate-x-32 max-2xl:translate-x-24 max-md:translate-x-16 max-sm:translate-x-8'
								: 'translate-x-[-764px]'
						} duration-200`}
					>
						užsi'
					</h1>

					<img
						src={veskLogo}
						alt="VESK logo"
						className={`absolute z-40 top-44 left-[128px] w-64 max-xl:hidden ${
							isHovered
								? 'translate-x-32 max-2xl:translate-x-16 max-md:translate-x-8'
								: 'translate-x-[-764px]'
						} duration-200`}
					/>
					{isWideScreen && (
						<img
							src={veskBigLogo}
							alt="logo"
							className={`absolute z-40 bottom-12 left-0 w-64 max-xl:hidden ${
								isHovered
									? 'translate-x-32 max-2xl:translate-x-16 max-md:translate-x-8'
									: 'translate-x-[-764px]'
							} duration-200`}
						></img>
					)}
					<h1
						className={`absolute z-40 text-white top-[316px] max-w-md font-medium text-7xl max-xl:hidden ${
							isHovered
								? 'translate-x-32 max-2xl:translate-x-16 max-md:translate-x-8'
								: 'translate-x-[-764px]'
						} duration-200`}
					>
						profesijai!
					</h1>

					<Link to={'/profesijos'}>
						<Button
							btnText={'Profesijos'}
							className={`absolute top-[448px] z-40 duration-200  max-xl:hidden ${
								isHovered
									? 'translate-x-32 max-2xl:translate-x-16 max-md:translate-x-8'
									: 'translate-x-[-768px]'
							}`}
						/>
					</Link>

					<div
						className={`absolute z-40 h-[2vw] max-h-[32px] w-screen rounded-full bg-green top-[546px]  max-xl:hidden ${
							isHovered ? 'translate-x-[-60vw]' : 'translate-x-[-100vw]'
						} duration-200`}
					></div>

					<div
						className={`absolute z-40 h-[2vw] max-h-[32px] w-screen rounded-full bg-cyan top-full rotate-[70deg] max-xl:hidden ${
							isHovered
								? 'translate-x-[30vw] translate-y-[40vw]'
								: 'translate-x-[45vw] translate-y-[70vw]'
						} duration-200`}
					></div>

					<div
						className={`absolute z-40 h-[2vw] max-h-[32px] w-screen rounded-full bg-yellow -rotate-[55deg] max-xl:hidden ${
							isHovered
								? 'translate-x-[45vw] translate-y-[-20vw]'
								: 'translate-x-[100vw] translate-y-[-100vw]'
						} duration-200`}
					></div>
				</>
			)}
		</section>
	);
}
