import React from 'react';
import { useNavigate } from 'react-router-dom';
import usePathConverter from '../../customHooks/usePathConverter';

export default function ProjectCard({ title, number, text, images, type }) {
	const navigate = useNavigate();
	const { convertTextToPath } = usePathConverter();

	const handleClick = () => {
		const path = `${type}/${convertTextToPath(title)}`;
		navigate(`/projektai/${path}`);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<div
			onClick={handleClick}
			className="flex flex-row gap-8 bg-white shadow-md rounded-lg px-12 py-8 max-sm:px-6 max-xm:py-4 cursor-pointer duration-150 hover:shadow-cyan hover:shadow-lg max-xl:flex-col"
		>
			<span className="flex flex-col gap-8 items-center max-w-64">
				{images.map((image, index) => (
					<img
						key={index}
						className="object-cover"
						src={image}
						alt={'Partner Logo'}
					/>
				))}
			</span>
			<div className="flex flex-col xl:w-3/4">
				<div className="overflow-y-auto max-h-32 mb-4 relative">
					<h3>{title}</h3>
					<div className="sticky bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-white"></div>
				</div>

				<p className="text-sm text-gray-500 mb-8">{number}</p>
				<div className="overflow-y-auto max-h-32 relative">
					<p>{text}</p>
					<div className="sticky bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-white"></div>
				</div>
			</div>
		</div>
	);
}
