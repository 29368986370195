import React from 'react';
import ReactMarkdown from 'react-markdown';
import DocumentCard from '../../components/DocumentCard';
import veskIcon from '../../../assets/svg/vesk/VESKicon.svg';

export default function AdministrativeLegal({
	title,
	description,
	mission,
	vision,
	values,
	documents,
}) {
	return (
		<section className="w-full relative mt-40 pl-96 pr-32 max-xl:px-32 max-sm:px-16 pb-32 flex flex-col gap-8">
			<h1>{title}</h1>
			{description && (
				<ReactMarkdown className="bg-white p-8 flex flex-col gap-4 rounded-xl shadow-md">
					{description}
				</ReactMarkdown>
			)}

			{mission && (
				<div>
					<h2 className="mb-4">Misija</h2>
					<ReactMarkdown className="bg-white p-8 flex flex-col gap-4 rounded-xl shadow-md">
						{mission}
					</ReactMarkdown>
				</div>
			)}
			{vision && (
				<div>
					<h2 className="mb-4">Vizija</h2>
					<ReactMarkdown className="bg-white p-8 flex flex-col gap-4 rounded-xl shadow-md">
						{vision}
					</ReactMarkdown>
				</div>
			)}
			{values && (
				<div>
					<h2 className="mb-4">Vertybės</h2>
					<ul className="flex gap-4 flex-wrap justify-between">
						{values.map((value, index) => (
							<li
								className="p-4 bg-white rounded-lg flex gap-2 shadow-md"
								key={index}
							>
								<img src={veskIcon} alt="VESK" className="w-6" />
								<p>{value}</p>
							</li>
						))}
					</ul>
				</div>
			)}
			{documents && (
				<div>
					<DocumentCard title={'Dokumentai'} files={documents} />
				</div>
			)}
		</section>
	);
}
