import React, { useState } from 'react';
import ContactElement from '../core/components/contacts/ContactElement';
import phoneSvg from '../assets/svg/contacts/phone-solid.svg';
import emailSvg from '../assets/svg/contacts/envelope-solid.svg';
import mapSvg from '../assets/svg/contacts/map-location-dot-solid.svg';
import DocumentCard from '../core/components/DocumentCard';
import ReactMarkdown from 'react-markdown';
import Table from '../core/components/Table';
import UsefulLink from '../core/components/UsefulLink';
import ImageCarousel from '../core/components/ImageCarousel';

export default function CardLP({
	title,
	description,
	documents,
	phone,
	email,
	location,
	ytLink,
	images,
	regUrl,
	tables,
	links,
}) {
	const hasContactInfo = phone || email || location;
	const [tableData, setTableData] = useState(
		tables && tables.length > 0 ? tables[0] : null
	);

	const handleTableRenderChange = (newRenderState) => {
		setTableData(newRenderState);
	};

	return (
		<main className="pb-32 pt-40 flex flex-col gap-8 px-40 max-2xl:px-16 max-sm:px-8 bg-neutral-50">
			<h1>{title}</h1>

			<section className="flex flex-col gap-8">
				<ReactMarkdown
					className={
						'reactMarkdown bg-white p-8 flex gap-8 flex-col rounded-xl shadow-md'
					}
				>
					{description}
				</ReactMarkdown>

				{ytLink && (
					<iframe
						className="aspect-video w-2/3 self-center"
						src={ytLink}
						title="YouTube video"
					></iframe>
				)}
				{tables && (
					<div className="">
						<header className="flex flex-row gap-4 text-white mb-4 max-lg:flex-col">
							{tables.map((item, index) => (
								<div
									key={index}
									onClick={() => handleTableRenderChange(item)}
									className="cursor-pointer"
								>
									<h3
										className={`text-2xl shadow-md  p-4 rounded-xl hover:bg-green duration-150 ${
											tableData.fields.category === item.fields.category
												? 'bg-green'
												: 'text-opacity-60 bg-cyan'
										}`}
									>
										{item.fields.category}
									</h3>
								</div>
							))}
						</header>
						<div className="overflow-x-auto">
							<Table
								headers={tableData.fields.headers}
								data={tableData.fields.rows}
							/>
						</div>
					</div>
				)}
				{images && (
					<div className="flex justify-center ">
						<ImageCarousel
							images={images.map((image) => image.fields.file.url)}
						/>
					</div>
				)}

				{documents && (
					<div>
						<DocumentCard title={'Dokumentai'} files={documents} />
					</div>
				)}
				{regUrl && (
					<a
						href={regUrl}
						target="_blank"
						className="px-4 py-2 bg-cyan rounded-xl text-white hover:bg-green duration-150 w-fit"
					>
						Registracija
					</a>
				)}
				{hasContactInfo && (
					<div>
						<h2 className="my-8">Kontaktai</h2>
						<div className="flex justify-between gap-8 2xl:gap-16 flex-wrap">
							{phone && (
								<ContactElement
									img={phoneSvg}
									alt={'Phone'}
									smallText={'Telefono nr.'}
									bigText={phone}
								/>
							)}
							{email && (
								<ContactElement
									img={emailSvg}
									alt={'Email'}
									smallText={'El. paštas'}
									bigText={email}
								/>
							)}
							{location && (
								<ContactElement
									img={mapSvg}
									alt={'Email'}
									smallText={'Adresas'}
									bigText={location}
								/>
							)}
						</div>
					</div>
				)}
				{links && (
					<div>
						<h2 className="my-8">Naudingos nuorodos</h2>
						<div className="flex gap-16 max-xl:gap-8 flex-wrap">
							{links.map((item, index) => (
								<UsefulLink
									key={index}
									title={item.fields.title}
									url={item.fields.url}
								/>
							))}
						</div>
					</div>
				)}
			</section>
		</main>
	);
}
