import React from 'react';
import { Link } from 'react-router-dom';
import usePathConverter from '../../customHooks/usePathConverter';

export default function FooterNav({ title, links, bgColor }) {
	const { convertTextToPath } = usePathConverter();
	const renderListItems = () => {
		return links.map((link, index) => {
			return (
				<li key={index} className="max-md:text-center">
					{link.url ? (
						<a
							href={link.url}
							className="opacity-80 cursor-pointer hover:opacity-100 duration:150 text-white"
							target="_blank"
							rel="noopener noreferrer"
						>
							{link.text}
						</a>
					) : (
						<Link
							to={convertTextToPath(link.title)}
							onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
							className="opacity-80 cursor-pointer hover:opacity-100 duration:150 text-white"
						>
							{link.text}
						</Link>
					)}
				</li>
			);
		});
	};

	return (
		<ul className="flex flex-col text-white gap-2 max-md:items-center ">
			<h4 className="text-2xl font-bold">{title}</h4>
			<div className={`h-1 w-12 bg-${bgColor}`}></div>
			{renderListItems()}
		</ul>
	);
}
