import React, { useState } from 'react';
import chevron from '../../assets/svg/nav/chevron-white.svg';

const ImageCarousel = ({ images }) => {
	const [imageIndex, setImageIndex] = useState(0);

	function showNextImage() {
		setImageIndex((index) => {
			if (index === images.length - 1) return 0;
			return index + 1;
		});
	}

	function showPrevImage() {
		setImageIndex((index) => {
			if (index === 0) return images.length - 1;
			return index - 1;
		});
	}

	return (
		<div className="w-2/3 aspect-video relative select-none self-center max-xl:w-full">
			{images.length > 1 ? (
				<>
					<div className="w-full h-full flex overflow-hidden rounded-3xl shadow-xl max-xl:rounded-xl">
						{images.map((url, index) => (
							<img
								key={index}
								src={url}
								alt={`${index}`}
								className="object-cover w-full h-full block flex-shrink-0 flex-grow-0 duration-300 ease-in-out"
								style={{ transform: `translateX(${-100 * imageIndex}%)` }}
							/>
						))}
					</div>

					<button
						onClick={showNextImage}
						className="carouselSliderBtn right-0 rotate-90"
					>
						<img src={chevron} alt="Next" className="" />
					</button>
					<button
						onClick={showPrevImage}
						className="carouselSliderBtn left-0 -rotate-90"
					>
						<img src={chevron} alt="Previous" />
					</button>
					<div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
						{images.map((_, index) => (
							<button
								key={index}
								onClick={() => setImageIndex(index)}
								className="w-2 h-2 hover:scale-125 duration-100"
							>
								{index === imageIndex ? (
									<div className="w-full h-full bg-cyan rounded-full"></div>
								) : (
									<div className="w-full h-full bg-white rounded-full"></div>
								)}
							</button>
						))}
					</div>
				</>
			) : (
				<img
					src={images[0]}
					alt=""
					className="object-cover w-full h-full block rounded-3xl shadow-xl"
				/>
			)}
		</div>
	);
};

export default ImageCarousel;
