import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import usePathConverter from '../customHooks/usePathConverter';

export default function Overlay({ img, title, active }) {
	const [isActive, setActive] = useState(() => {
		return sessionStorage.getItem('overlayClosed') !== 'true' && active;
	});
	const navigate = useNavigate();
	const { convertTextToPath } = usePathConverter();

	useEffect(() => {
		sessionStorage.setItem('overlayClosed', !isActive);
	}, [isActive]);

	const handleNav = () => {
		const path = `${convertTextToPath(title)}`;
		navigate(`${path}`);
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setActive(false);
	};

	const closeOverlay = () => {
		setActive(false);
	};

	return (
		<div
			className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${
				isActive ? '' : 'hidden'
			}`}
			onClick={closeOverlay}
		>
			<div className="relative p-8 rounded-xl flex flex-col gap-8 max-w-[1100px] max-h-fit">
				<div
					className="absolute right-2 top-2 cursor-pointer w-6 h-6 flex items-center justify-center"
					onClick={closeOverlay}
				>
					<div className={`absolute bg-white w-0.5 h-4 rotate-45`}></div>
					<div className={`absolute bg-white w-0.5 h-4 -rotate-45`}></div>
				</div>

				<div className="cursor-pointer relative" onClick={handleNav}>
					<img src={img} alt={title} className="object-cover rounded-lg w-96" />
				</div>
			</div>
		</div>
	);
}
