import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SideNav from '../core/features/SideNav';
import ServicesFeature from '../core/features/services/ServicesFeature';

export default function Services({ state, data }) {
	const [isRender, setRender] = useState(state);

	const navigate = useNavigate();

	useEffect(() => {
		setRender(state);
	}, [state]);

	const handleRenderChange = (newRenderState) => {
		setRender(newRenderState);
	};

	const changeRouteFunction = (route) => {
		navigate(route);
	};

	const navigationItems = data.map((item) => ({
		key: item.fields.route,
		label: item.fields.title,
		route: `/paslaugos/${item.fields.route}`,
	}));

	function renderComponent() {
		const selectedService = data.find((item) => item.fields.route === isRender);

		if (!selectedService) {
			return null;
		}

		return (
			<ServicesFeature
				title={selectedService.fields.title}
				description={selectedService.fields.description}
				descriptionSecondParagraph={
					selectedService.fields.descriptionSecondParagraph
				}
				descriptionThirdParagraph={
					selectedService.fields.descriptionThirdParagraph
				}
				workingDays={selectedService.fields.workingDays}
				workingFrom={selectedService.fields.workingFrom}
				workingTill={selectedService.fields.workingTill}
				email={selectedService.fields.email}
				phone={selectedService.fields.phone}
				address={selectedService.fields.address}
				images={selectedService.fields.images.map(
					(image) => image.fields.file.url
				)}
			/>
		);
	}

	return (
		<main className=" flex gap-32 bg-gray-100 min-h-screen">
			<SideNav
				title={'Paslaugos'}
				onRenderChange={handleRenderChange}
				currentRenderState={isRender}
				navigationItems={navigationItems}
				changeRoute={changeRouteFunction}
			/>
			{renderComponent()}
		</main>
	);
}
